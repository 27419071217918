var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home_top"},[_c('div',{staticClass:"inner inner1"},[_c('div',{staticClass:"left_item"},[_c('h1',[_vm._v("Simple loan，fast review convenient and fast")]),_c('h4',[_vm._v("Low daily interest and good service")]),_c('h4',[_vm._v("Download app")]),_c('div',{staticClass:"item_download"},[_c('img',{attrs:{"src":require("@/assets/images/loanImg/Google-play.png")},on:{"click":function($event){return _vm.downloadApp(1)}}}),_c('img',{attrs:{"src":require("@/assets/images/loanImg/Apple-store.png")},on:{"click":function($event){return _vm.downloadApp(2)}}})])]),_vm._m(0)]),_c('div',{staticClass:"inner inner1_5 flex"},[_c('img',{attrs:{"src":require("@/assets/images/loanImg/inner1-5-left.png"),"alt":""}}),_c('div',{staticClass:"center_right"},[_vm._m(1),_vm._m(2),_vm._m(3),_c('h1',{staticClass:"item_downloads",on:{"click":function($event){_vm.isShowDown = !_vm.isShowDown}}},[_vm._v(" Apply now! ")]),(_vm.isShowDown)?_c('show-download'):_vm._e()],1)]),_vm._m(4),_vm._m(5),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right_item"},[_c('img',{attrs:{"src":require("@/assets/images/loanImg/top_app.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Maximum amount     "),_c('span',[_vm._v("₱ 10,000")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Repayment Term     "),_c('span',[_vm._v("0-180 days")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Annual interest rate "),_c('span',[_vm._v("10%-35%")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inner inner2",attrs:{"id":"selector1"}},[_c('h1',[_vm._v("Loan anytime, anywhere, and approve loans in seconds")]),_c('div',{staticClass:"items"},[_c('div',{staticClass:"left_item"},[_c('img',{attrs:{"src":require("@/assets/images/loanImg/icon_people.png"),"alt":""}})]),_c('div',{staticClass:"right_item"},[_c('div',{staticClass:"item_info"},[_c('div',{staticClass:"info_tips"},[_c('div',{staticClass:"items"},[_c('img',{staticClass:"info_img1",attrs:{"src":require("@/assets/images/loanImg/app-icon-1.png"),"alt":""}}),_c('div',[_c('h3',[_vm._v("Registered")]),_c('p',[_vm._v("Download the app and complete the registration")])])]),_c('div',{staticClass:"items"},[_c('img',{staticClass:"info_img1",attrs:{"src":require("@/assets/images/loanImg/app-icon-2.png"),"alt":""}}),_c('div',[_c('h3',[_vm._v("Submit information")]),_c('p',[_vm._v("Fill in the information and increase the loan amount")])])]),_c('div',{staticClass:"items"},[_c('img',{staticClass:"info_img1",attrs:{"src":require("@/assets/images/loanImg/app-icon-3.png"),"alt":""}}),_c('div',[_c('h3',[_vm._v("Get amount")]),_c('p',[_vm._v("After the review is confirmed, the funds will be automatically sent and deposited into your bank account")])])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inner inner5"},[_c('h3',[_vm._v("Why choose MicroLoan?")]),_c('div',{staticClass:"inner5_item"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"left_item"},[_c('img',{attrs:{"src":require("@/assets/images/loanImg/about_inner5-1.png"),"alt":""}}),_c('div',{staticClass:"item_info"},[_c('h2',[_vm._v("Easy to use")]),_c('p',[_vm._v("Register anytime, easy to use")])])])]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"left_item"},[_c('img',{attrs:{"src":require("@/assets/images/loanImg/about_inner5-2.png"),"alt":""}}),_c('div',{staticClass:"item_info"},[_c('h2',[_vm._v("Fast approval")]),_c('p',[_vm._v("Information transparency, quick review")])])])]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"left_item"},[_c('img',{attrs:{"src":require("@/assets/images/loanImg/about_inner5-3.png"),"alt":""}}),_c('div',{staticClass:"item_info"},[_c('h2',[_vm._v("Low cost")]),_c('p',[_vm._v("Low daily interest rate, high-quality service")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inner inner6"},[_c('h3',[_vm._v("Kind tips")]),_c('div',{staticClass:"inner5_item"},[_c('p',[_vm._v(" We attach great importance to the safety and security of our users’ data. We will never authorize your data to any third-party platform without your consent. If you are coerced by any of its employees while using MicroLoan to use your personal information that causes inconvenience to you or your family, we encourage you to retain evidence and complain to us. We will not tolerate such behavior! We will pursue it to the end! ")]),_c('p',[_vm._v(" MicroLoan clearly states the “Loan Agreement” within the APP, and MicroLoan will only credit your loan amount to your account after both parties agree, and you can click the “Cancel” button to cancel the loan application during the loan application process. ")]),_c('p',[_vm._v(" If you make a mistake or regret applying for a loan, please contact us in time and return the principal within 24 hours. According to our company policy, we will cancel your loan application for free. If you have any questions, please feel free to contact us, we will provide you with the best service. ")]),_c('p',[_vm._v(" Your feedback is the driving force for us to move forward. You are welcome to give us suggestions to improve our products and help us create a better product experience together. Thank you very much for your support. MicroLoan reserves the final all right of interpretation of the above content. ")]),_c('p',[_vm._v(" Contact Email: microloanapp@outlook.com ")]),_c('p')])])
}]

export { render, staticRenderFns }