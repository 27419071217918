<template>
  <div class="home_top">
    <div class="inner inner1">
      <div class="left_item">
        <h1>Simple loan，fast review convenient and fast</h1>
        <h4>Low daily interest and good service</h4>
        <h4>Download app</h4>
        <div class="item_download">
          <img src="@/assets/images/loanImg/Google-play.png" @click="downloadApp(1)">
          <img src="@/assets/images/loanImg/Apple-store.png" @click="downloadApp(2)">
        </div>
      </div>
      <div class="right_item">
        <img src="@/assets/images/loanImg/top_app.png" alt="">
      </div>
    </div>
    <div class="inner inner1_5 flex">
      <img src="@/assets/images/loanImg/inner1-5-left.png" alt="">
      <div class="center_right">
        <p>Maximum amount &nbsp;&nbsp;&nbsp; <span>₱ 10,000</span></p>
        <p>Repayment Term &nbsp;&nbsp;&nbsp;&nbsp;<span>0-180 days</span></p>
        <p>Annual interest rate&nbsp;<span>10%-35%</span></p>
        <h1 class="item_downloads" @click="isShowDown = !isShowDown">
          Apply now!
        </h1>
        <show-download v-if="isShowDown" />
      </div>
    </div>
    <div id="selector1" class="inner inner2">
      <h1>Loan anytime, anywhere, and approve loans in seconds</h1>
      <div class="items">
        <div class="left_item">
          <img src="@/assets/images/loanImg/icon_people.png" alt="">
        </div>
        <div class="right_item">
          <div class="item_info">
            <div class="info_tips">
              <div class="items">
                <img class="info_img1" src="@/assets/images/loanImg/app-icon-1.png" alt="">
                <div>
                  <h3>Registered</h3>
                  <p>Download the app and complete the registration</p>
                </div>
              </div>
              <div class="items">
                <img class="info_img1" src="@/assets/images/loanImg/app-icon-2.png" alt="">
                <div>
                  <h3>Submit information</h3>
                  <p>Fill in the information and increase the loan amount</p>
                </div>
              </div>
              <div class="items">
                <img class="info_img1" src="@/assets/images/loanImg/app-icon-3.png" alt="">
                <div>
                  <h3>Get amount</h3>
                  <p>After the review is confirmed, the funds will be automatically sent and deposited into your bank account</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="inner inner5">
      <h3>Why choose MicroLoan?</h3>
      <div class="inner5_item">
        <div class="content">
          <div class="left_item">
            <img src="@/assets/images/loanImg/about_inner5-1.png" alt="">
            <div class="item_info">
              <h2>Easy to use</h2>
              <p>Register anytime, easy to use</p>
            </div>
          </div>
        </div>
        <div class="content">
          <div class="left_item">
            <img src="@/assets/images/loanImg/about_inner5-2.png" alt="">
            <div class="item_info">
              <h2>Fast approval</h2>
              <p>Information transparency, quick review</p>
            </div>
          </div>
        </div>
        <div class="content">
          <div class="left_item">
            <img src="@/assets/images/loanImg/about_inner5-3.png" alt="">
            <div class="item_info">
              <h2>Low cost</h2>
              <p>Low daily interest rate, high-quality service</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="inner inner6">
      <h3>Kind tips</h3>
      <div class="inner5_item">
        <p>
          We attach great importance to the safety and security of our users’ data. We will never authorize your data
          to any third-party platform without your consent. If you are coerced by any of its employees while using
          MicroLoan to use your personal information that causes inconvenience to you or your family, we encourage
          you to retain evidence and complain to us. We will not tolerate such behavior! We will pursue it to the end!
        </p>
        <p>
          MicroLoan clearly states the “Loan Agreement” within the APP, and MicroLoan will only credit your loan amount
          to your account after both parties agree, and you can click the “Cancel” button to cancel the loan application
          during the loan application process.
        </p>
        <p>
          If you make a mistake or regret applying for a loan, please contact us in time and return the principal within
          24 hours. According to our company policy, we will cancel your loan application for free. If you have any questions,
          please feel free to contact us, we will provide you with the best service.
        </p>
        <p>
          Your feedback is the driving force for us to move forward. You are welcome to give us suggestions to improve our
          products and help us create a better product experience together. Thank you very much for your support.
          MicroLoan reserves the final all right of interpretation of the above content.
        </p>
        <p>
          Contact Email: microloanapp@outlook.com
        </p>
        <p />
      </div>
    </div>
  </div>
</template>

<script>
import showDownload from '@/components/showDownload'
export default {
  components: {
    showDownload
  },
  data() {
    return {
      isShowDown: false
    }
  },
  methods: {
    downloadApp(type) {
      switch (type) {
        case 1:
          window.open('https://play.google.com/store/apps/details?id=micro.loan.app', '_blank')
          break
        case 2:
          window.open('https://apps.apple.com/us/app/micro-loan/id1664036395', '_blank')
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .home_top{
    min-width: 1200px;
    background: #fff;
    .inner{
      width: 100%;
      padding:50px calc((100% - 1200px) / 2) 0;
      margin: 0 auto;
    }
    .inner1{
      // background: linear-gradient(258deg, #43C5D6 0%, #4484EC 100%);
      background: #5E50E9;
      height: 426px;
      display: flex;
      .left_item{
        // background: #fff;
        float: left;
        padding: 25px;
        border-radius: 10px;
        // width: 500px;
        h1{
          font-size: 40px;
          color: white;
          font-weight: 700;
          margin-bottom: 30px;
          line-height: 60px;
        }
        h4{
          font-size: 16px;
          line-height: 22px;
          color: white;
          font-weight: normal;
          margin-bottom: 30px;
        }
        .item_progress{
          width: 230px;
          p{
            width: 90px;
            text-align: center;
            line-height: 30px;
            border-radius: 5px;
            color: white;
            font-size: 12px;
            font-weight: bold;
            margin: 0 auto 10px;
            background: #4488EB;
          }
          img{
            width: 245px;
            height: 15px;
          }
        }
        .item_info{
          margin: 20px 0;
          display: flex;
          height: 170px;
          .info_img1{
            width: 40px;
          }
          .info_tips{
            display: flex;
            flex-direction: column;
            p{
              flex: 1;
              display: flex;
              box-sizing: border-box;
              // margin-left: 10px;
              font-size: 12px;
              color: #666666;
              align-items: center;
              img{
                margin-right: 10px;
              }
            }
            // }
            // p:nth-child(1){
            //   padding-top: 8px;
            // }
            // :nth-child(2){
            //   align-items: center;
            // }
            // :nth-child(3){
            //   align-items:flex-end;
            //   padding-bottom: 8px;
            // }
          }
          .info_img2{
            width: 135px;
            height: 135px;
          }
        }
        .item_download{
          margin-top: 20px;
          color: white;
          border-radius: 5px;
          img{
            width: 150px;
            height: 45px;
            margin-right: 15px;
          }
        }
      }
      .right_item{
        float: right;
        // margin-right: 50px;
        img{
          width: 650px;
        }
      }
    }
    .inner1_5{
      padding:50px calc((100% - 1200px) / 2) ;
      img,.center_right{
        width: 50%;
      }
      .center_right{
        border-radius: 0 20px 20px 0;
        background: linear-gradient(180deg, #D05EE9 0%, #3853EE 100%);
        padding: 50px 60px;
        position: relative !important;
        text-align: center;
        p{
          text-align: left;
          font-size: 24px;
          line-height: 60px;
          color: white;
          display: flex;
          span{
            flex: 1;
            margin-left: 100px;
            font-weight: bold;
            font-size: 26px;
          }
        }
        .item_downloads{
          margin-top: 50px;
          text-align: center;
          color: #4354EE;
          background: white;
          line-height: 60px;
          border-radius: 15px;
        }
          .down{
            font-size: 24px !important;
            width: 110%;
            top: 400px;
            right: -30px;
            padding: 30px 90px 40px;
            box-shadow: 0px 10px 40px 1px rgba(0,0,0,0.16);
          }
      }
    }
    .inner2{
      // height: 420px;
      background:#F8F9FF;
      text-align: center;
      padding:50px calc((100% - 1500px) / 2);
      h1{
        margin-bottom: 60px;
      }
      .items{
        display: flex;
        }
      .left_item{
        flex: 1.1;
        img{
          width: 200px;
          height: 400px;
        }
      }
      .right_item{
        flex: 1;
        margin-right:30px;
        text-align: left;
        line-height: 30px;
        p{
          color:#2665D9 ;
          font-size: 20px;
          font-weight: bold;
        }
        .item_tips{
          display: flex;
          width: 460px;
          p{
            width: 70px;
            font-size: 12px;
            color: #333333;
            text-align: left;
          }
        }
        .item_info{
          // margin: 20px 0;
          height: 100%;
          display: flex;
          .info_img1{
            width: 50px;
          }
          .info_tips{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .items{
              flex: 1;
              display: flex;
              background: white !important;
              box-shadow: 0 0 2px #999;
              box-sizing: border-box;
              padding: 10px 60px 10px 20px;
              border-radius: 10px;
              align-items: center;
              margin-bottom: 30px;
              img{
                margin-right: 10px;
              }
            }
            .items:nth-last-child(1){
              margin-bottom: 0px !important;
            }
            p{
              font-size: 12px;
              color: #666666;
              line-height: 20px;
            }
          }
        }
      }
    }
    .inner3{
      margin: 0 auto 50px;
      h3{
        font-size: 24px;
      }
      img{
        width: 100%;
      }
      .inner3_item{
        margin: 50px 0;
        display: flex;
        justify-content: space-between;
        .content{
          background: white;
          width: 280px;
          padding: 25px 20px 30px 20px;
          border-radius: 10px;
          box-shadow: 0px 3px 20px 1px rgba(93,93,93,0.1600);
          .top_item{
            display: flex;
            justify-content: space-between;
            p{
              width: 88px;
            }
            img{
              width: 40px;
              height: 30px;
            }
          }
          .line{
            width: 120px;
            height: 2px;
            background: #4485EC;
            margin: 15px 0;
          }
          .bottom_item{
            line-height: 18px;
            color: #666666;
          }
        }
      }
    }
    .inner4{
      position: relative;
      height: 364px;
      background:#F2FAFF ;
      display: flex;
      justify-content: space-between;
      .left_item{
        img{
          width: 280px;
          height: 300px;
        }
      }
      .right_item{
        margin-right:50px;
        p{
          margin: 40px 0;
          color:#2665D9 ;
          font-size: 20px;
          font-weight: bold;
        }
        img{
          position: absolute;
          width: 190px;
          height:220px;
          right: calc(15%);
          top: 0px;
        }
        .item_tips{
          background:#E0EEFE ;
          padding: 20px 10px;
          border-radius: 10px;
          width: 450px;
          line-height: 20px;
        }
      }
    }
    .inner5{
      padding: 50px 0;
      background: #FAFAFA;
      h3{
        font-size: 24px;
        color: black;
        text-align: center;
        margin-bottom: 50px;
      }
      h2{
        line-height: 30px;
        margin-bottom: 20px;
      }
      .inner5_item{
        // width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 0px calc((100% - 1100px) / 2) 0;
        font-size: 14px;
        text-align: center;
        line-height: 16px;
        .content{
          flex: 1;
          background: #fff;
          padding: 40px;
          border-radius: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 25px;
          box-shadow: 0 0 5px #999;
          img{
            // margin-top: 50px;
            width: 100px;
          }
        }
      }
    }
    .inner6{
      // padding: 50px 0;
      padding-bottom: 50px;
      h3{
        font-size: 24px;
        color: black;
        text-align: center;
        margin-bottom: 50px;
      }
      p{
        line-height: 18px;
        margin-bottom: 20px;
      }
    }
  }
@media only screen and (max-width: 720px){
  .home_top{
    min-width: calc(100vw) !important;
    .inner{
      min-width: calc(100vw) !important;
      display: flex;
      flex-wrap: wrap;
    }
    .inner1{
      padding: 0px calc((100% - 1200px) / 2) 0;;
      height: 610px;
      position: relative;
      .item_download{
          display: flex;
        img{
          margin-bottom: 20px;
          width: 130px !important;
          height: 45px !important;
        }
      }
      .left_item{
        margin: 0px 20px 20px;
        // height: 500px;
        h1{
          font-size: 30px;
          line-height: 50px;
        }
      }
      .right_item{
        width: calc(100vw);
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width: 100% !important;
          position: absolute;
          bottom: 0;
        }
      }
    }
    .inner1_5{
      display: none;
    }
    .inner2{
      width: calc(100vw);
      // padding: 20px;
      // height: 730px;
      h1{
        line-height: 40px;
        margin-bottom: 0;
      }
      .items{
        flex-direction: column;
      }
      .left_item{
        width: calc(100vw);
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width: 200px;
          height: 350px;
          margin: 50px 0;
        }
      }
      .right_item{
        margin-right: 0;
        width: calc(100vw - 40px);
        margin: 0 auto;
        p{
          font-size: 18px;
        }
        .items{
          padding: 20px !important;
          text-align: center;
          img{
            margin-bottom: 20px;
          }
        }
        // img{
        //   // width: 100%;
        //   // height: 60px;
        // }
        .item_tips{
          width: calc(100vw - 40px);
          p{
            font-size: 12px !important;
            width: 70px;
          }
        }
      }
    }
    .inner3{
      width: calc(100vw - 40px) !important;
      margin: 0 auto  30px !important;
      h3{
        font-size: 18px;
        padding: 0 20px;
      }
      img{
        width: 90%;
        margin: 0 auto;
      }
      .inner3_item{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .content{
          margin: 20px 0;
        }
      }
    }
    .inner4{
      height: 600px;
      .left_item{
        width: calc(100vw);
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width: 200px;
          height: 220px;
        }
      }
      .right_item{
        width: calc(100vw - 40px) !important;
        margin: 0 auto !important;
        position: relative;
        .item_tips{
          width: 100%;
        }
        img{
          position: absolute;
          width: 100px;
          height: 140px;
          right: 0;
          top: -20px;
        }
      }
    }
    .inner5{
      min-width: calc(100vw - 40px) !important;
      width: calc(100vw - 40px) !important;
      margin: 0 auto !important;
      padding: 30px 0;
      h3{
        width: 100%;
        font-size: 25px;
        text-align: center;
        margin-bottom: 20px;
      }
      .inner5-bg{
        padding: 20px  !important;
        height: 100px !important;
        font-size: 12px !important;
      }
      .inner5_item{
        margin:  0 auto;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        .content{
          margin: 20px 0;
        }
      }
    }
    .inner6{
      flex-direction: column;
      padding: 30px 20px 30px;
      h3{
        text-align: center;
        margin-bottom: 30px;
      }
    }
  }
}
</style>
